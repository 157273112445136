<template>
 <div>
   <snack-view
     :message="snack.message"
     :activate="snack.active"
     :bg-color="snack.color"
   ></snack-view>
   <div
     v-if="submited==true"
     class="auth-wrapper auth-v1"
   >
     <v-progress-circular
       :size="100"
       :width="7"
       color="primary"
       indeterminate

     ></v-progress-circular>
   </div>
   <fieldset
     v-else
     class="py-6 mb-4">
     <legend class="ml-4 pl-2 pr-2">
       Recherche de décodeur
     </legend>
     <v-form
       ref="searchForm"
     >
       <v-row class="mx-auto">
         <v-col
           cols="12"
           md="5"
         >
           <v-select
             v-model="recherche.type"
             outlined
             dense
             :rules="LocalNameRules"
             :items="typeRecherche"
             item-value="id"
             item-text="name"
             :menu-props="{ offsetY : true }"
             placeholder="Type de recherche"
             label="Type de recherche"
             hide-details="auto"
             return-object
             @change='changePrefix'
           ></v-select>
         </v-col>
         <v-col
           cols="12"
           md="4"
         >
           <v-text-field
             v-model="recherche.value"
             label="valeur de la recherche"
             :rules="LocalNameRules"
             outlined
             dense
             placeholder="valeur de la recherche"
             hide-details="auto"
             :prefix="prefix"
           ></v-text-field>
         </v-col>
         <v-col
           cols="12"
           md="3"
         >
           <v-btn
             color='primary'
             @click='searchDecodeur'
           >
             <v-icon>fas fa-search</v-icon> Rechercher
           </v-btn>
         </v-col>
       </v-row>
     </v-form>

   </fieldset>
   <v-dialog
     v-model="dialog"
     persistent
   >
     <v-card>
       <v-card-title>
         <span>  Résultat de la recherche</span>
       </v-card-title>
       <v-divider></v-divider>
       <v-data-table
         :headers="headersDetails"
         :items="decodeurs"
         :items-per-page="-1"
         sort-by="id"
         show-select
         v-model="decoderSelected"
         :disable-pagination="true"
         :hide-default-footer="true"
       >


         <template #[`item.actions`]="{ item }">
           <div class="col-12">
             <v-btn
               outlined
               color="success"
               class="mt-1"
               :disabled="item.state===-1"
               @click="addDecodeur(item)"
             >
               <v-icon small class='mr-2'>
                 fas fa-plus
               </v-icon>
               Ajouter
             </v-btn>
           </div>
         </template>
       </v-data-table>
       <v-divider></v-divider>
       <v-card-actions>
         <v-spacer></v-spacer>
         <v-btn
           class='mt-4'
           color="error"
           @click="close"
           outlined
         >
           Annuler
         </v-btn>
         <v-btn
           class='mt-4'
           color="primary"
           @click="validate"
         >
           Valider
         </v-btn>
       </v-card-actions>
     </v-card>
   </v-dialog>
 </div>
</template>

<script>

import { ref } from '@vue/composition-api'
import SnackView from '@/views/SnackView'
import Http from '@/helpers/http'

export default {
  components: {
    SnackView,
  },
  setup() {

    const snack = ref({
      message: '',
      active: false,
      color: undefined,
    })

    const dialog = ref(false)

    const searchForm = ref(null)

    const decoderSelected = ref([])

    const decodeurs = []

    const recherche = []

    const prefix = ""

    const submited = false

    const typeRecherche = [

      {
        id: 2,
        name: 'Numéro de décodeur',
        code: 'clabo',
        prefix: '',
      },
      {
        id: 1,
        name: 'Numéro de téléphone',
        code: 'phone',
        prefix: '00237',
      },
      {
        id: 3,
        name: 'Numéro d\'abonné',
        code: 'numabo',
        prefix: '',
      },
    ]

    const LocalNameRules = [
      v => !!v || 'Ce champ est réquis',
    ]

    return {

      decodeurs,
      recherche,
      typeRecherche,
      LocalNameRules,
      searchForm,
      snack,
      dialog,
      decoderSelected,
      prefix,
      submited,
      headersDetails: [
        { text: 'N°d\'abonné', value: 'numabo', divider: true },
        { text: 'Décodeur', value: 'clabo', divider: true },
        { text: 'Offre', value: 'optionmajeureabo', divider: true },
        { text: 'Propriétaire', value: 'nomabo', divider: true },
        { text: 'Ville', value: 'villabo', divider: true },
        { text: 'Date de fin', value: 'finabo', divider: true },
        // { text: 'Date d\'activation', value: 'date', divider: true },
        // { text: 'Action', value: 'actions', sortable: false, divider: true, },
      ],
    }
  },
  methods:{
    searchDecodeur(){
      let donnees = this.recherche

      console.log(donnees)

      // if(donnees.type !== undefined && donnees.value !== undefined && donnees.value.trim() !== ''){
      if(this.searchForm.validate()){

        if(donnees.type.id===1 || donnees.type.id===2){


          this.submited = true
          let param = {[donnees.type.code]:donnees.type.prefix + donnees.value}

          Http.post('get-info-abonnee', param)
            .then(reponse => {

              console.log(reponse.data)

              let data = reponse.data

              this.decodeurs = []

              for(let i = 0; i <data.length; i++){

                let param2 = {numabo:data[i].numabo,clabo : data[i].clabo}

                Http.post('get-contrat-abonnee', param2)
                  .then(rep => {

                    this.decodeurs.push({

                      id: i+1,
                      clabo: data[i].clabo,
                      nomabo: data[i].nomabo,
                      numabo: data[i].numabo,
                      optionmajeureabo: data[i].optionmajeureabo,
                      prenomabo: data[i].prenomabo,
                      villabo: data[i].villabo,
                      finabo: rep.data.finabo

                    })

                    if(this.decodeurs.length===data.length){
                      this.submited = false
                      this.dialog = true
                      console.log(this.decodeurs)
                    }

                  }).catch(error => {
                  this.submited = false
                  console.log(error)
                })

              }

            }).catch(error => {
            this.submited = false
            console.log(error)
          })

        }else if(donnees.type.id===3){
          let param = {[donnees.type.code]:donnees.value}

          Http.post('get-contrat-abonnee', param)
            .then(rep => {
              this.decodeurs = []
              this.dialog = true
              console.log(rep.data)

            }).catch(error => {
            console.log(error)
          })
        }


      }
      else{
        // this.snack.active = true
        // this.snack.color = "error"
        // this.snack.message = "Veuillez remplir le formulaire convenablement"
      }
    },

    validate() {
      this.close()

      console.log(this.decoderSelected)
    },

    close() {
      this.dialog = false
      this.decodeurs = []
    },

    addDecodeur(){

    },

    changePrefix(){

      this.prefix = this.recherche.type.prefix
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/@core/preset/preset/pages/auth.scss';
</style>
